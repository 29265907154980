import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController, NavController } from '@ionic/angular';
import { hasPermission } from './auth/permissions';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private router: Router,
    private menuCtrl: MenuController,
    private navCtrl: NavController
  ) {}

  open(path) {
    const encoded = encodeURIComponent(path);
    this.navCtrl.setDirection('root');
    this.router.navigateByUrl(encoded);
    this.menuCtrl.toggle();
  }

  logout() {
    localStorage.clear();
    this.router.navigateByUrl('login');
  }

  permissions = {
    agent: hasPermission('view_agent'),
    invoice: hasPermission('view_invoice'),
    route: hasPermission('view_route'),
    crate: hasPermission('view_crate'),
    rate: hasPermission('view_price'),
    products: hasPermission('view_product'),
    cash: hasPermission('view_ledgertransactions'),
    expense: hasPermission('view_expenselist'),
    production: hasPermission('view_production'),
    damage: hasPermission('view_return'),
    dispatch: hasPermission('view_dispatch'),
    damage_approval: hasPermission('change_return'),
    incentive: hasPermission('view_incentive'),
  };

  public appPages = [
    { title: 'Dashboard', url: 'dashboard', icon: 'home' },
    { title: 'Agents Master', url: 'manage-agents', icon: 'person-circle' },
    { title: 'Products Master', url: 'product-master', icon: 'cube' },
    { title: 'Rate Master', url: 'rate-setting', icon: 'cash' },
    { title: 'Route Master', url: 'route-master', icon: 'map' },
    // { title: 'Create Invoice', url: 'invoice', icon: 'document-text' },
    { title: 'Manage Invoices', url: 'invoice-list', icon: 'documents' },
    { title: 'Cash receipts', url: 'cash-receipt', icon: 'receipt' },
    { title: 'Manage Crates', url: 'crate-master', icon: 'cube' },
    // { title: 'Daily Report', url: 'daily-report', icon: 'document' },
    { title: 'Expense Enteries', url: 'expense-enteries', icon: 'wallet' },
    { title: 'Dispatch', url: 'dispatch', icon: 'trail-sign' },
    { title: 'Production Entry', url: 'production-entry', icon: 'reader' },
    { title: 'Damage Entry', url: 'damage-entry', icon: 'reader' },
    { title: 'Damage Approval', url: 'damage-approval', icon: 'checkmark-done' },
    { title: 'Incentive Approval', url: 'incentive-approval', icon: 'checkmark' },
    { title: 'Closing Stock', url: 'closing-stock', icon: 'bag-add' },
    // { title: 'Bill Print', url: 'bill-print', icon: 'print' },
    // { title: 'Sales Report', url: 'sales-report', icon: 'document' },
    // { title: 'Crate Report', url: 'crate-report', icon: 'document' },
    // { title: 'Return Report', url: 'return-report', icon: 'document' },
    // { title: 'Payment Report', url: 'payment-report', icon: 'document' },
  ];

  ngOnInit() {}
}
