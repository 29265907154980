export const hasPermission = (permission) => {
  let a = JSON.parse(localStorage.getItem('permissions'));

  let permissions;

  if (a) {
    permissions = a.map((e) => {
      return e.codename;
    });
  }

  if (permissions) {
    return permissions.includes(permission);
  } else {
    return false;
  }
};
