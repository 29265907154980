import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';

// permissions
let a = JSON.parse(localStorage.getItem('permissions'));

let permissions;

if (a) {
  permissions = a.map((e) => {
    return e.codename;
  });
}

@Injectable({
  providedIn: 'root',
})
export class PermissionGuard implements CanActivate {
  constructor() {}
  canActivate(route: ActivatedRouteSnapshot): boolean {
    const permission = route.data.permission;
    if (permissions) {
      return permissions.includes(permission);
    } else {
      return true;
    }
  }
}
