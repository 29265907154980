import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { PermissionGuard } from './auth/permission.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardPageModule),

    canActivate: [AuthGuard],
  },
  {
    path: 'rate-setting',
    loadChildren: () =>
      import('./rate-setting/rate-setting.module').then((m) => m.RateSettingPageModule),
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'view_price',
    },
  },

  {
    path: 'manage-agents',
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'view_agent',
    },
    loadChildren: () =>
      import('./manage-agents/manage-agents.module').then((m) => m.ManageAgentsPageModule),
  },
  {
    path: 'product-master',
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'view_product',
    },
    loadChildren: () =>
      import('./product-master/product-master.module').then((m) => m.ProductMasterPageModule),
  },
  {
    path: 'expense-enteries',
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'view_expenselist',
    },
    loadChildren: () =>
      import('./expense-enteries/expense-enteries.module').then((m) => m.ExpenseEnteriesPageModule),
  },
  {
    path: 'route-master',
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'view_route',
    },
    loadChildren: () =>
      import('./route-master/route-master.module').then((m) => m.RouteMasterPageModule),
  },
  {
    path: 'invoice',
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'view_invoice',
    },
    loadChildren: () => import('./invoice/invoice.module').then((m) => m.InvoicePageModule),
  },
  {
    path: 'invoice-list',
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'view_invoice',
    },
    loadChildren: () =>
      import('./invoice-list/invoice-list.module').then((m) => m.InvoiceListPageModule),
  },
  {
    path: 'edit-invoice',
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'change_invoice',
    },
    loadChildren: () =>
      import('./edit-invoice/edit-invoice.module').then((m) => m.EditInvoicePageModule),
  },
  {
    path: 'crate-master',
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'view_crate',
    },

    loadChildren: () =>
      import('./crate-master/crate-master.module').then((m) => m.CrateMasterPageModule),
  },
  {
    path: 'cash-receipt',
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'view_expenselist',
    },
    loadChildren: () =>
      import('./cash-receipt/cash-receipt.module').then((m) => m.CashReceiptPageModule),
  },
  {
    path: 'dispatch',
    loadChildren: () => import('./dispatch/dispatch.module').then((m) => m.DispatchPageModule),
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'view_dispatch',
    },
  },
  {
    path: 'production-entry',
    loadChildren: () =>
      import('./production-entry/production-entry.module').then((m) => m.ProductionEntryPageModule),
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'view_production',
    },
  },
  {
    path: 'damage-entry',
    loadChildren: () =>
      import('./damage-entry/damage-entry.module').then((m) => m.DamageEntryPageModule),
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'view_return',
    },
  },
  {
    path: 'damage-approval',
    loadChildren: () =>
      import('./damage-approval/damage-approval.module').then((m) => m.DamageApprovalPageModule),
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'change_return',
    },
  },
  {
    path: 'incentive-approval',
    loadChildren: () =>
      import('./incentive-approval/incentive-approval.module').then(
        (m) => m.IncentiveApprovalPageModule
      ),
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'view_incentive',
    },
  },
  {
    path: 'closing-stock',
    loadChildren: () =>
      import('./closing-stock/closing-stock.module').then((m) => m.ClosingStockPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'bill-print',
    loadChildren: () => import('./bill-print/bill-print.module').then((m) => m.BillPrintPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'sales-report',
    loadChildren: () =>
      import('./sales-report/sales-report.module').then((m) => m.SalesReportPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'crate-report',
    loadChildren: () =>
      import('./crate-report/crate-report.module').then((m) => m.CrateReportPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'return-report',
    loadChildren: () =>
      import('./return-report/return-report.module').then((m) => m.ReturnReportPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'payment-report',
    loadChildren: () =>
      import('./payment-report/payment-report.module').then((m) => m.PaymentReportPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'view-invoice',
    loadChildren: () =>
      import('./view-invoice/view-invoice.module').then((m) => m.ViewInvoicePageModule),
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'view_invoice',
    },
  },
  {
    path: 'realisation-report',
    loadChildren: () => import('./realisation-report/realisation-report.module').then( m => m.RealisationReportPageModule)
  },
  {
    path: 'agent-sales-report',
    loadChildren: () => import('./agent-sales-report/agent-sales-report.module').then( m => m.AgentSalesReportPageModule)
  },
  {
    path: 'incentive-report',
    loadChildren: () => import('./incentive-report/incentive-report.module').then( m => m.IncentiveReportPageModule)
  },
  {
    path: 'datewise-sales-report',
    loadChildren: () => import('./datewise-sales-report/datewise-sales-report.module').then( m => m.DatewiseSalesReportPageModule)
  },
  {
    path: 'hr-upload-attendence',
    loadChildren: () => import('./hr-upload-attendence/hr-upload-attendence.module').then( m => m.HrUploadAttendencePageModule)
  },
  {
    path: 'hr-attendence',
    loadChildren: () => import('./hr-attendence/hr-attendence.module').then( m => m.HrAttendencePageModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
